<template>
	<div :class="['meta-item', slug.replace('_', '-'), {'has-icon': !!icon}]">
		<dt>
			<span
				v-if="icon"
				class="meta-icon"
			>
				<svg
					width="16"
					height="16"
				>
					<use :xlink:href="`#${icon}`" />
				</svg>
			</span>
			<strong class="meta-title">
				{{ name }}
			</strong>
		</dt>
		<dd>
			<template v-if="isFilter">
				<span
					v-for="(el, index) in (Array.isArray(value) ? value : [value])"
					:key="index"
				>
					<template v-if="index">, </template>
					<a @click="changeFilter(slug, el, true)">{{ el }}</a>
				</span>
			</template>
			<span v-else>
				{{ Array.isArray(value) ? value.join(', ') : value }}
			</span>
		</dd>
	</div>
</template>

<script>
import {getIcon} from '@/utils/icon';
import {FilterMixin} from '@/mixins';


export default {
	mixins: [FilterMixin],
	props: {
		name: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		},
		value: {
			type: [Array, Number, String],
			required: true
		},
		isFilter: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		icon() {
			const {slug} = this;
			return getIcon(`assets/icons/ui/${slug}`);
		}
	}
};
</script>
