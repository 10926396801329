export function getIcon(name) {
	let icon;

	try {
		icon = (name.includes('assets/'))
			? require(`@/${name}.svg?sprite`)
			: require(`scharfzahn-svg/${name}.svg?sprite`)
		;
		return icon.default.id;
	} catch (e) {
		// I want application to not crush, but don't care about the message
	}

	return icon;
}
